import { END_DATE_OF_MONTH, START_DATE_OF_MONTH } from '@/libs/filterDate'
import moment from 'moment'

export const dropdownCod = [
  {
    label: 'COD',
    value: 'cod',
  },
  {
    label: 'non COD',
    value: 'non_cod',
  },
]

export const dropdownExpedition = [
  {
    label: 'JNE',
    value: 'jne',
  },
  {
    label: 'SiCepat',
    value: 'sicepat',
  },
  {
    label: 'IDExpress',
    value: 'idexpress',
  },
  {
    label: 'Ninja',
    value: 'ninja',
  },
  {
    label: 'J&T',
    value: 'jnt',
  },
  {
    label: 'SAP',
    value: 'sap',
  },
]

export const chartOptions = {
  colors: ['#FBA63C', '#47AEE4'],
  chart: {
    type: 'area',
    stacked: false,
    height: 500,
    width: '100%',
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
  },
  stroke: {
    curve: 'smooth',
  },
  yaxis: {
    tickAmount: 9,
    forceNiceScale: true,
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  xaxis: {
    type: 'datetime',
    tickAmount: 15,
    categories: [],
    min: START_DATE_OF_MONTH,
    max: END_DATE_OF_MONTH,
    labels: {
      formatter: (val, timestamp) => moment(new Date(timestamp)).format('DD'),
    },
  },
  fill: {
    type: ['gradient', 'gradient'],
    opacity: 1,
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [20, 100, 100, 100],
    },
  },
  noData: {
    text: 'Halo admin, saat ini sedang tidak ada data',
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'center',
  },
  tooltip: {
    shared: false,
    custom: null,
  },
}
