<template>
  <BCard>
    <BCol lg="12">
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <BButton
            size="sm"
            class="btn-back"
            @click="$router.go(-1)"
          >
            <img
              src="https://storage.googleapis.com/komerce/assets/illustration/arrow-left-white.svg"
            >
          </BButton>
          <span class="text-[#222222] ml-1 font-bold text-[20px]">
            Rasio Pickup Bulanan
          </span>
        </div>
        <div
          class="flex"
          style="gap:8px"
        >
          <div class="rounded-[8px] w-[120px] border-[#e2e2e2] border">
            <button
              class="btn d-flex align-items-center justify-content-between px-1 w-full"
              type="button"
              data-toggle="dropdown"
              style="padding: 8px 8px!important"
            >
              {{ dropdownExpedition[activeIndexExpedition].label }}
              <BImg
                class="ml-1"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
              />
            </button>
            <div
              class="dropdown-menu"
              style="z-index: 99;"
            >
              <a
                v-for="(item, index) in dropdownExpedition"
                :key="index"
                class="dropdown-item"
                @click="handleExpedition(index)"
              >{{ item.label }}</a>
            </div>
          </div>
          <MonthlyPicker
            v-model="filterMonthPickup"
            class="monthly-user-grow"
            date-format="MMMM YYYY"
            :month-labels="locale.monthNames"
            alignment="center"
            display="true"
            :max="this.$moment().endOf('month')"
          />
          <div class="rounded-[8px] w-[120px] border-[#e2e2e2] border">
            <button
              class="btn d-flex align-items-center justify-content-between px-1 w-full"
              type="button"
              data-toggle="dropdown"
              style="padding: 8px 8px!important"
            >
              {{ dropdownCod[activeIndexCod].label }}
              <BImg
                class="ml-1"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-down-1.svg"
              />
            </button>
            <div
              class="dropdown-menu"
              style="z-index: 99;"
            >
              <a
                v-for="(item, index) in dropdownCod"
                :key="index"
                class="dropdown-item"
                @click="handleCod(index)"
              >{{ item.label }}</a>
            </div>
          </div>
        </div>
      </div></BCol>
    <BCol
      lg="12"
      class="mt-2"
    >
      <BOverlay
        :show="loading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <Apexcharts
          ref="myChart"
          height="400"
          :series="dataChart"
          type="area"
          :options="chartOptions"
        />
      </BOverlay>
    </BCol>
  </BCard>
</template>

<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import {
  LABELDAY, LABELMONTH,
  MONTH,
  MONTH_NAME,
  YEAR,
} from '@/libs/filterDate'
import { newAxiosIns } from '@/libs/axios'
import { BButton, BCard, BCol } from 'bootstrap-vue'
import Apexcharts from 'vue-apexcharts'
import MonthlyPicker from 'vue-monthly-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {
  chartOptions,
  dropdownCod,
  dropdownExpedition,
} from './config'

export default {
  components: {
    BCard,
    BCol,
    BButton,
    Apexcharts,
    MonthlyPicker,
  },
  data() {
    return {
      loading: false,
      activeIndexCod: 0,
      activeIndexExpedition: 0,
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: LABELDAY,
        monthNames: LABELMONTH,
      },
      filterMonthPickup: this.$moment(),
      dropdownCod,
      dropdownExpedition,
      chartOptions,
    }
  },
  watch: {
    filterMonthPickup: {
      handler() {
        this.getDataChart()
      },
    },
    activeIndexCod: {
      handler() {
        this.getDataChart()
      },
    },
    activeIndexExpedition: {
      handler() {
        this.getDataChart()
      },
    },
  },
  mounted() {
    this.getDataChart()
  },
  methods: {
    getDaysInMonth(year, month) {
      return new Date(year, month, 0).getDate()
    },
    handleExpedition(index) {
      this.activeIndexExpedition = index
    },

    async getDataChart() {
      this.loading = true
      const params = {
        shipping: this.dropdownExpedition[this.activeIndexExpedition].value,
        type: this.dropdownCod[this.activeIndexCod].value,
        month: MONTH(this.filterMonthPickup),
        year: YEAR(this.filterMonthPickup),
      }

      const url = 'event/api/v1/dashboard/order/monthly-pickup'
      await newAxiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data
          this.loading = false
          this.dataChart = [
            {
              name: 'Total Pickup',
              data: data.map(item => item.total_pickup),
            },
            {
              name: 'First Pickup New User',
              data: data.map(item => item.total_pickup_new_user),
            },

          ]
          this.chartOptions = {
            ...this.chartOptions,
            xaxis: {
              ...this.chartOptions.xaxis,
              categories: data.map(e => e.date),
            },
            tooltip: {
              ...this.chartOptions.tooltip,

              custom: ({ dataPointIndex, w }) => {
                let htmlRender = ''
                const arrayData = [...w.globals.series]

                arrayData.forEach((x, idx) => {
                  if (w.globals.collapsedSeriesIndices.indexOf(idx) !== -1) {
                    htmlRender += ''
                  } else {
                    htmlRender += `<div class='flex flex-column'><div class="my-0 mt-1 flex items-center" ">
                 <div style="background-color: ${this.chartOptions.colors[idx]};" class="h-4 w-4 rounded-full"></div> <span class="text-black ml-1"> ${x[dataPointIndex]}
                </div></div>`
                  }
                })
                return `<div class="flex flex-column px-1 py-1 rounded">
              <div>${dataPointIndex + 1} ${MONTH_NAME(
  this.filterMonthPickup,
)} ${YEAR(this.filterMonthPickup)}</div>
              ${htmlRender}
            </div>`
              },
            },
          }
        })
        .catch(err => {
          this.$toast(
            {
              component: ToastificationContentVue,
              props: {
                title: 'Error',
                icon: 'AlertCircleIcon',
                text: err.message,
                variant: 'danger',
              },
            },
            1000,
          )
        })
      this.loading = false
    },
    handleCod(index) {
      this.activeIndexCod = index
    },
  },
}
</script>

<style lang="scss" scope>
@import './user-growth-performance.scss'
</style>
